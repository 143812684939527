// 問い合わせ
// 検索
import React, { useState } from 'react';
import { graphql, navigate } from 'gatsby';
import Helmet from 'react-helmet';
import Axios from 'axios';
import Layout from '../components/layout';
import ContactValidation from '../assets/js/validation/contact';

const Contact = props => {
  //タイトル等
  const siteName = props.data.site.siteMetadata.title;
  const title = `お問い合わせ | ${siteName}`;
  const description = 'お問い合わせページです。';

  // お問い合わせapiのurl
  const contactApiUrl = props.data.site.siteMetadata.contactApiUrl;

  // googleリキャプチャサイトキー
  const gRecaptchaSiteKey = props.data.site.siteMetadata.gRecaptchaSiteKey;

  // フック
  const [nameError, setNameError] = useState({ empty: false, more: false });
  const [kanaError, setKanaError] = useState({ empty: false, more: false });
  const [mailError, setMailError] = useState({
    empty: false,
    noType: false,
    noEq: false
  });
  const [contactItemError, setContactItemError] = useState({ empty: false });
  const [contentError, setContentError] = useState({
    empty: false,
    more: false
  });
  const [errorFlag, setErrorFlag] = useState(false);
  const [submitErrorFlag, setSubmitErrorFlag] = useState(false);
  const [values, setValues] = useState({
    name: '',
    kana: '',
    mail: '',
    mailCfm: '',
    contactItem: '',
    content: ''
  });
  const [submitDisabled, setSubmitDisabled] = useState(false);

  const handleChange = e => {
    const target = e.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;
    setValues({ ...values, [name]: value });
  };

  const contactSubmit = e => {
    e.preventDefault();
    // ボタン無効化
    setSubmitDisabled(true);

    // バリデーション
    let validation = new ContactValidation();

    // バリデーション情報取得
    let name = validation.name(values.name, 50);
    let kana = validation.kana(values.kana, 100);
    let mail = validation.mail(values.mail, values.mailCfm);
    let contactItem = validation.contactItem(values.contactItem);
    let content = validation.content(values.content, 1000);

    // 色設定
    isAllTrue(name) ? normalForm('name') : errorForm('name');
    isAllTrue(kana) ? normalForm('kana') : errorForm('kana');
    isAllTrue(mail) ? normalForm('mail') : errorForm('mail');
    mail.isEq ? normalForm('mailCfm') : errorForm('mailCfm');
    contactItem ? normalForm('contactItem') : errorForm('contactItem');
    isAllTrue(content) ? normalForm('content') : errorForm('content');

    // エラー設定
    setNameError({ empty: !name.isSet, more: !name.isOrLess });
    setKanaError({ empty: !kana.isSet, more: !kana.isOrLess });
    setMailError({
      empty: !mail.isSet,
      noType: !mail.isMail,
      noEq: !mail.isEq
    });
    setContactItemError({ empty: !contactItem });
    setContentError({ empty: !content.isSet, more: !content.isOrLess });
    // エラーフラグ設定 送信処理
    if (
      isAllTrue(name) &&
      isAllTrue(kana) &&
      isAllTrue(mail) &&
      contactItem &&
      isAllTrue(content)
    ) {
      // google リキャプチャ トークン取得
      window.grecaptcha.ready(() => {
        window.grecaptcha
          .execute(gRecaptchaSiteKey, { action: 'contact' })
          .then(token => {
            // トークンを加える
            let postValues = { ...values, token: token };
            // 送信処理
            Axios.post(contactApiUrl, postValues)
              .then(result => {
                // 送信が完了したか
                if (result.data.status_code === 200) {
                  // 送信完了画面へ
                  // 画面遷移
                  // location.href = '/contact-comp'
                  navigate('/contact-comp');
                } else {
                  // 送信エラー・ボタン有効化
                  setSubmitErrorFlag(true);
                  setSubmitDisabled(false);
                }
              })
              .catch(error => {
                console.log(error);
                // 送信エラー・ボタン有効化
                setSubmitErrorFlag(true);
                setSubmitDisabled(false);
              });
          });
      });
    } else {
      // エラーフラグ・ボタン有効化
      setErrorFlag(true);
      setSubmitDisabled(false);
    }
  };

  return (
    <Layout title={title} description={description} pageType="contact">
      <Helmet>
        <script
          src={
            'https://www.google.com/recaptcha/api.js?render=' +
            gRecaptchaSiteKey
          }
        ></script>
      </Helmet>
      <h1 className="mb-2 text-2xl font-black">お問い合わせ</h1>
      <div className="w-full mb-5">
        <form className="max-w-screen-xl mx-auto" onSubmit={contactSubmit}>
          <div className="mb-5">
            <label htmlFor="name">名前&#42;</label>
            {nameError.empty && <p className="error-text">必須項目です</p>}
            {nameError.more && (
              <p className="error-text">50文字以内で入力してください</p>
            )}
            <input
              type="text"
              id="name"
              name="name"
              className="text-box border-gray-200"
              value={values.name}
              onChange={handleChange}
            />
          </div>
          <div className="mb-5">
            <label htmlFor="kana">フリガナ&#42;</label>
            {kanaError.empty && <p className="error-text">必須項目です</p>}
            {kanaError.more && (
              <p className="error-text">100文字以内で入力してください</p>
            )}
            <input
              type="text"
              id="kana"
              name="kana"
              className="text-box border-gray-200"
              value={values.kana}
              onChange={handleChange}
            />
          </div>
          <div className="mb-5">
            <label htmlFor="mail">メールアドレス&#42;</label>
            {mailError.empty && <p className="error-text">必須項目です</p>}
            {mailError.noType && (
              <p className="error-text">メールアドレスを入力してください</p>
            )}
            {mailError.noEq && <p className="error-text">一致していません</p>}
            <input
              type="text"
              id="mail"
              name="mail"
              className="text-box border-gray-200"
              value={values.mail}
              onChange={handleChange}
            />
          </div>
          <div className="mb-5">
            <label htmlFor="mailCfm">メールアドレス確認&#42;</label>
            {mailError.noEq && <p className="error-text">一致していません</p>}
            <input
              type="text"
              id="mailCfm"
              name="mailCfm"
              className="text-box border-gray-200"
              value={values.mailCfm}
              onChange={handleChange}
            />
          </div>
          <div className="mb-5">
            <label htmlFor="contactItem">お問い合わせ項目&#42;</label>
            {contactItemError.empty && (
              <p className="error-text">必須項目です</p>
            )}
            <div className="relative">
              <div className="material-icons absolute top-11px right-2">
                keyboard_arrow_down
              </div>
              <select
                id="contactItem"
                name="contactItem"
                className="select-box appearance-none border-gray-200"
                value={values.contactItem}
                onChange={handleChange}
              >
                <option value="" className="hidden">
                  選択してください
                </option>
                <option value="記事の訂正">記事の訂正</option>
                <option value="記事の削除">記事の削除</option>
                <option value="その他">その他</option>
              </select>
            </div>
          </div>
          <div className="mb-5">
            <label htmlFor="content">お問い合わせ内容&#42;</label>
            {contentError.empty && <p className="error-text">必須項目です</p>}
            {contentError.more && (
              <p className="error-text">1000文字以内で入力してください</p>
            )}
            <textarea
              id="content"
              name="content"
              className="textarea border-gray-200"
              value={values.content}
              onChange={handleChange}
            />
            {/* <p>1000文字以内</p> */}
          </div>
          <div className="mb-5 text-center text-sm">
            <p>
              個人情報の取り扱いに関しては
              <a
                href="/privacy-policy"
                target="_blank"
                rel="noreferrer"
                className="text-blue-300"
              >
                こちら
              </a>
            </p>
          </div>
          <div className="text-center">
            {errorFlag && (
              <p className="error-text">エラー項目があるので送信出来ません</p>
            )}
            {submitErrorFlag && (
              <>
                <p className="error-text">送信エラー</p>
                <p className="error-text">しばらく待って再度送信してください</p>
              </>
            )}
            <button type="submit" disabled={submitDisabled} className="submit">
              送信
            </button>
          </div>
        </form>
      </div>
    </Layout>
  );
};

export default Contact;

// 連想配列のすべての値がtrueか
const isAllTrue = obj => {
  let flag = true;
  Object.keys(obj).forEach(key => {
    if (!obj[key]) {
      flag = false;
    }
  });
  return flag;
};

// フォームの枠をエラー(赤)に変更
const errorForm = idName => {
  let formItem = document.getElementById(idName);
  formItem.classList.remove('border-gray-200');
  formItem.classList.add('border-red-600');
};

// フォームの色を通常状態(グレー)に変更
const normalForm = idName => {
  let formItem = document.getElementById(idName);
  formItem.classList.remove('border-red-600');
  formItem.classList.add('border-gray-200');
};

export const contactQuery = graphql`
  query contactQuery {
    site {
      siteMetadata {
        title
        contactApiUrl
        gRecaptchaSiteKey
      }
    }
  }
`;
