// バリデーション
class Validation {
  constructor() {
    this.mailReg = /^[A-Za-z0-9]{1}[A-Za-z0-9_.-]*@{1}[A-Za-z0-9_.-]{1,}\.[A-Za-z0-9]{1,}$/;
  }
  // 文字が入力されているか 空白・改行抜き
  isSet(text) {
    return text.trim().length !== 0;
  }

  // 同じ値か 型まで完全一致
  isEq(arg1, arg2) {
    return arg1 === arg2;
  }

  // 指定文字数以上・以下か 空白あり
  textOrMore(text, num) {
    return text.length >= num;
  }
  textOrLess(text, num) {
    return text.length <= num;
  }

  // メールアドレスかの判定
  isMail(text) {
    return this.mailReg.test(text);
  }

  // セレクトボックスで選択されているか
  isSelect(value) {
    return value !== '';
  }
}

export default Validation;
