// お問い合わせのバリデーション
import Validation from './validation';

class ContactValidation extends Validation {
  // 名前
  name = (text, num) => {
    // 値が入っているか
    let isSet = super.isSet(text);
    // 指定値以下か
    let isOrLess = super.textOrLess(text, num);

    return { isSet, isOrLess };
  };

  // フリガナ
  kana = (text, num) => {
    // 値が入っているか
    let isSet = super.isSet(text);
    // 指定値以下か
    let isOrLess = super.textOrLess(text, num);

    return { isSet, isOrLess };
  };

  // メールアドレス
  mail = (mail, mailCfm) => {
    // 値が入っているか
    let isSet = super.isSet(mail);
    // メアドか
    let isMail = super.isMail(mail);
    // 同じ値か
    let isEq = super.isEq(mail, mailCfm);

    return { isSet, isMail, isEq };
  };

  // セレクトボックス 選択されているか
  contactItem = value => super.isSelect(value);

  // 内容
  content = (text, num) => {
    // 値が入っているか
    let isSet = super.isSet(text);
    // 指定値以下か
    let isOrLess = super.textOrLess(text, num);

    return { isSet, isOrLess };
  };
}

export default ContactValidation;
